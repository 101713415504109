import React, {useCallback, useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-number-input";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {
    commentCreateRequest,
    commentDeleteRequest,
    companyCreateRequest,
    companyEditRequest
} from "../store/actions/company";
import Loader from "./Loader";
import {useLocation} from "react-router";
import qs from "query-string";
import {employeeRequest} from "../store/actions/employee";
import {departmentRequest} from "../store/actions/department";
import Api from "../Api";
import {company, global} from "../translate";
import CompanyHistory from "./CompanyHistory";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 800 ? window.innerWidth - 60 : 800,
    height: window.innerHeight - 60,
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
};

function CompanyCreateAndEdit({create, setCreate, edit, setEdit, phone, title}) {
    const location = useLocation();
    const dispatch = useDispatch()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [department, setDepartment] = useState('Developer')
    const [dataAll, setData] = useState(null)
    const statusData = useSelector((state) => state.department.companyStatusData)

    const employeeData = useSelector((state) => state.employee.employeeData)
    const departmentData = useSelector((state) => state.department.departmentData)
    const companyStatus = useSelector((state) => state.company.companyStatus)
    const profileData = useSelector(state => state.users.userData)
    const lang = useSelector(state => state.users.lang)

    const [form, setForm] = useState({
        title: '',
        description: '',
        phone: [],
        manager: profileData?.email,
        status: '0'
    })
    const [number, setPhone] = useState({
        id: new Date(),
        phone: ''
    })

    const [comment, setComment] = useState({
        id: new Date(),
        setter: profileData?.email,
        description: '',
    })
    const [reminder, setReminder] = useState({
        id: new Date(),
        setter: profileData?.email,
        description: '',
    })

    const [allComments, setAllComments] = useState([])
    const [allReminder, setAllReminder] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                if (edit) {
                    const {data} = await Api.getCompanySingleId(edit)
                    setData(data.company)
                }
            } catch (e) {
                console.log(e)
            }
        })()
    }, [edit])

    useEffect(() => {
        (async () => {
            await dispatch(departmentRequest())

            if (phone) {
                setPhone({id: new Date(), phone})
                // setForm((prev) => ({
                //     ...prev,
                //     phone,
                // }))
            } else {
                setPhone({id: new Date(), phone: ''})

            }
            if (title) {
                setForm((prev) => ({
                    ...prev,
                    title,
                }))
            } else {
                setForm((prev) => ({
                    ...prev,
                    title: '',
                }))
            }
            if (edit && dataAll) {
                const final = {}
                final.manager = dataAll?.manager?.user?.email
                final.phone = dataAll?.phoneNumbers?.length ? dataAll?.phoneNumbers : [{
                    phone: dataAll?.phone,
                    id: new Date()
                }]
                final.title = dataAll?.title
                final.description = dataAll?.description
                final.status = dataAll?.status ? dataAll?.status.toString() : ''
                setDepartment(dataAll?.manager?.department?.title)
                setAllComments(dataAll?.comment)
                setAllReminder(dataAll?.reminder)
                setForm(final)
                await dispatch(employeeRequest(1000, 1, '', dataAll?.manager?.department?.title))

                // setDepartment(editItem[0]?.department.title)
            } else {
                await dispatch(employeeRequest(1000, 1, '', 'Developer'))
                await dispatch(departmentRequest())
            }
            // if(!edit){
            //     setForm((prev) => ({
            //         ...prev,
            //         title:'',
            //     }))
            // }
        })()

    }, [edit, phone, dataAll, title])

    const handleInput = useCallback((name, ev,) => {

        if (name === 'phone') {
            setPhone((prev) => ({
                ...prev,
                phone: ev,
            }))
        } else {

            setForm((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
        }
    }, [])

    const handleReminder = useCallback((name, ev) => {
        setReminder((prev) => ({
            ...prev,
            [name]: ev,
        }));
    }, [])

    const handleCommentDelete = useCallback(async (id) => {

        if (typeof id !== 'object') {
            setAllComments(allComments.filter(a => a.id !== id));
            await dispatch(commentDeleteRequest(id))
        } else {
            setAllComments(allComments.filter(a => a.id !== id));
        }
    }, [allComments])
    const handleReminderDelete = useCallback(async (id) => {
        try {
            if (typeof id !== 'object') {
                setAllReminder(allReminder.filter(a => a.id !== id));
                await Api.deleteReminder(id)
            } else {
                setAllReminder(allReminder.filter(a => a.id !== id));
            }
            toast.success("Reminder is deleted")
        } catch (e) {

        }
    }, [allReminder])
    const handlePhoneDelete = useCallback(async (id) => {
        try {
            if (typeof id !== 'object') {
                setForm((prev) => ({
                    ...prev,
                    phone: prev.phone.filter(a => a.id !== id)
                }));
                await Api.deletePhone(id)

            } else {
                setForm((prev) => ({
                    ...prev,
                    phone: prev.phone.filter(a => a.id !== id)
                }));
            }
        } catch (e) {
            console.log(e)
        }
    }, [number])

    const handleEmployerRequest = useCallback(async (ev) => {
        setDepartment(ev.target.value)

        await dispatch(employeeRequest(1000, 1, '', ev.target.value))
    }, [])

    const handleCommentAdd = useCallback(() => {
        setAllComments((prev) => ([
            ...prev,
            comment
        ]))
        setComment({
            id: new Date(),
            setter: profileData.email,
            description: '',
        })
    }, [comment, profileData])
    const handleReminderAdd = useCallback(async () => {
        try {
            const data = await Api.createReminder(edit, reminder)
            toast.success('Reminder is sent')
            setAllReminder((prev) => ([
                ...prev,
                reminder
            ]))
            setReminder({
                id: new Date(),
                setter: profileData.email,
                description: '',
            })
        } catch (e) {
            toast.error('Reminder is sent')
        }
    }, [reminder, profileData, edit])
    const handleNumberAdd = useCallback(() => {
        setForm((prev) => ({
            ...prev,
            phone: [...prev.phone, number]
        }))
        setPhone({id: new Date(), phone: ''})
    }, [number])

    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault();
        if (number.phone) {
            form.phone = [...form.phone, number]
            setPhone({id: new Date(), phone: ''})
        }


        if (!form.title || !form.phone || !form.manager || form.status === '0') {
            let name = ''
            for (let i in form) {
                if ((!form[i] || form[i] === '0') && i !== 'sip_operator') {
                    try {
                        name = i.split('_').join(" ")
                    } catch (e) {
                    }
                    // document.getElementById(i).style.borderBottomColor ='red'
                    toast.error("Please enter " + name.toUpperCase())
                }
            }
        } else {

            if (edit) {

                await dispatch(companyEditRequest(edit, form, query.page || 1, query.limit || 10, query.search || '', query.startTime || '', new Date() || '', query.searchByManager || '', query.status || '', query.phoneFil || '', query.isTest || '',
                    query.isBlocked || '',))
                if(comment.description){
                    await dispatch(commentCreateRequest(edit, [comment]))
                }
                if (allComments.length) {
                    let isNew = false
                    allComments.map(l => {
                        if (typeof l.id === 'object' && l.description) {
                            isNew = true
                        }
                    })


                    if (isNew) {
                        await dispatch(commentCreateRequest(edit, allComments))

                    }

                }
                if(reminder.description){
                    await Api.createReminder(edit, reminder)
                    toast.success('Reminder is sent')
                    setAllReminder((prev) => ([
                        ...prev,
                        reminder
                    ]))
                }

                setEdit(0)
            } else {
                await dispatch(companyCreateRequest(form, query.page || 1, query.limit || 10, query.search || '', query.startTime || '', new Date() || '', query.searchByManager || '', query.status || '', query.phoneFil || '', query.isTest || '',
                    query.isBlocked || '',))
                setCreate(false)
            }
            setForm({
                title: '',
                description: '',
                phone: [],
                manager: '',
                status: '0'
            })
        }
    }, [comment, form, edit, allComments, number, reminder, location.search])
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(event).then();
        }
    }, [comment, form, edit, allComments, number, reminder, location.search]);
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create || !!edit}
                onClose={() => edit ? setEdit(0) : setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!create || !!edit}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title">
                            <span style={{marginBottom: 30, color: 'black', fontSize: 25}}
                                  className="d-flex justify-content-between">
                                <span>{company.title[+lang]}</span>
                                <span style={{cursor: 'pointer'}}
                                      onClick={() => edit ? setEdit(0) : setCreate(false)}>x</span>
                            </span>
                        </Typography>
                        <form onKeyDown={handleKeyDown} onSubmit={handleSubmit}
                              className="d-flex flex-column justify-content-around">
                            <div className="modalCreateAndUpdate row">
                                <label className="col-md-6 m-0">
                                    <span>{company.name[+lang]}</span>
                                    <input
                                        readOnly={edit && form.manager !== profileData?.email && !profileData?.is_admin}
                                        style={{marginBottom: 40}}
                                        placeholder={`${global.enter[+lang]} ${company.title[+lang]} ${company.name[+lang]}`}
                                        value={form.title}
                                        onChange={(ev) => handleInput('title', ev)}/>

                                </label>
                                <label className="col-md-6 m-0">
                                    <span>{company.desc[+lang]}</span>
                                    <textarea
                                        readOnly={edit && form.manager !== profileData?.email && !profileData?.is_admin}
                                        style={{margin: 8, marginBottom: 40,}}
                                        placeholder={`${global.enter[+lang]} ${company.title[+lang]} ${company.desc[+lang]}`}
                                        value={form.description}
                                        onChange={(ev) => handleInput('description', ev)}/>
                                </label>
                                <label className="col-md-12 " style={{marginBottom: 20,}}>
                                    {global.status[+lang]}
                                    <select
                                        disabled={edit && form.manager !== profileData?.email && !profileData?.is_admin}
                                        style={{
                                            width: '100%',
                                            height: 40,
                                            outline: 'none',
                                            border: '1px solid #686868',
                                        }}
                                        value={form.status}
                                        onChange={(ev) => handleInput('status', ev)}>
                                        <option value="">{global.select[+lang]}</option>
                                        {statusData.length ? statusData.map(s => (
                                            <option key={s.id} value={s.id}>{s.title}</option>
                                        )) : null}
                                    </select>
                                </label>
                                <label className="col-md-12 m-0">
                                    <span>{global.phone[+lang]}:</span>
                                    {form.phone.map((p, i) => (
                                        <div key={p.id} className="d-flex align-items-center flex-row">
                                            <PhoneInput
                                                readOnly
                                                style={{width: '92%', marginBottom: 20}}
                                                placeholder={`${global.enter[+lang]} ${global.phone[+lang]}`}
                                                value={p.phone}
                                                onChange={(ev) => handleInput('phone', ev)}/>
                                            {profileData.is_admin ?
                                                <span style={{cursor: 'pointer'}}
                                                      onClick={() => handlePhoneDelete(p.id)}>x</span> : null}
                                        </div>

                                    ))}
                                    <div className="d-flex align-items-center flex-column">
                                        <PhoneInput
                                            readOnly={edit && form.manager !== profileData?.email && !profileData?.is_admin}
                                            style={{width: '92%', marginBottom: 20}}
                                            placeholder={`${global.enter[+lang]} ${global.phone[+lang]}`}
                                            value={number.phone}
                                            onChange={(ev) => handleInput('phone', ev)}/>
                                        <div onClick={handleNumberAdd}
                                             className="m-0 createBtn">{global.add[+lang]} {global.phone[+lang]}
                                        </div>
                                    </div>
                                </label>
                                {profileData.is_admin ? <>
                                    <label style={{marginBottom: 25}} className="col-md-6">
                                        <span>{company.dep[+lang]}</span>
                                        <select style={{border: '1px solid #686868',}} value={department}
                                                onChange={(ev) => handleEmployerRequest(ev)}>
                                            {departmentData.map((d) => (
                                                <option value={d.title} key={d.id}>{d.title}</option>
                                            ))}
                                        </select>
                                    </label>
                                    <label style={{marginBottom: 25}} className="col-md-6">
                                        <span>{company.manager[+lang]}</span>
                                        <select style={{border: '1px solid #686868',}} value={form.manager}
                                                onChange={(ev) => handleInput('manager', ev)}>
                                            <option value="">{global.select[+lang]} {company.manager[+lang]}</option>

                                            {employeeData?.employees?.map((d) => (
                                                <option value={d.user.email} key={d.id}>{d.user.email}</option>
                                            ))}
                                        </select>
                                    </label></> : edit ?
                                    <>
                                        <label style={{marginBottom: 25}} className="col-md-6">
                                            <span>{company.dep[+lang]}:</span>
                                            <select style={{border: '1px solid #686868',}} disabled value={department}>
                                                {departmentData.map((d) => (
                                                    <option value={d.title} key={d.id}>{d.title}</option>
                                                ))}
                                            </select>
                                        </label>
                                        <label style={{marginBottom: 25}} className="col-md-6">
                                            <span>{company.manager[+lang]}</span>
                                            <select style={{border: '1px solid #686868',}} disabled
                                                    value={form.manager}>
                                                <option
                                                    value="">{global.select[+lang]} {company.manager[+lang]}</option>

                                                {employeeData?.employees?.map((d) => (
                                                    <option value={d.user.email} key={d.id}>{d.user.email}</option>
                                                ))}
                                            </select>
                                        </label></> : null}

                                {allComments.map(c => (
                                    <label key={c.id} className="col-md-12">
                                        <p className="d-flex align-items-center justify-content-between">
                                            <span>{company.comment[+lang]}</span>
                                            {profileData.email === c.setter || profileData.is_admin ?
                                                <span style={{cursor: 'pointer'}}
                                                      onClick={() => handleCommentDelete(c.id)}>x</span> : null}
                                        </p>

                                        <textarea
                                            style={{marginBottom: 5}}
                                            placeholder={`${global.enter[+lang]} ${company.title[+lang]} ${company.comment[+lang]}`}
                                            value={c.description}
                                            onChange={(ev) => setComment({
                                                setter: profileData.email,
                                                description: ev.target.value
                                            })}/>
                                        {c.createdAt ?
                                            <span style={{fontSize: 10, marginBottom: 20, display: 'inline-block'}}>Created at: {new Date(c.createdAt).toLocaleString('en-US', {
                                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                                hour12: false,
                                            })}</span> : null}
                                        {c.setter ? <span style={{
                                            fontSize: 10,
                                            marginBottom: 20,
                                            display: 'inline-block'
                                        }}>By: <span>{c.setter}</span></span> : null}
                                    </label>
                                ))}
                                {edit ? dataAll?.old_com?.map(c => (
                                    <label key={c.id} className="col-md-12">
                                        <textarea
                                            style={{marginBottom: 5}}
                                            placeholder={`${global.enter[+lang]} ${company.title[+lang]} ${company.comment[+lang]}`}
                                            value={c.messages}
                                            disabled={true}/>
                                        {c.created_at ?
                                            <span style={{fontSize: 10, marginBottom: 20, display: 'inline-block'}}>Created at: {new Date(c.created_at).toLocaleString('en-US', {
                                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                                hour12: false,
                                            })}</span> : null}
                                        {c.username ? <span style={{
                                            fontSize: 10,
                                            marginBottom: 20,
                                            display: 'inline-block'
                                        }}>By: <span>{c.username}</span></span> : null}
                                    </label>
                                )):null}
                                {edit ?
                                    <>
                                        <label style={{marginBottom: 25}} className="col-md-12">
                                            <span>{company.comment[+lang]}</span>
                                            <textarea
                                                style={{marginBottom: 40}}
                                                placeholder={`${global.enter[+lang]} ${company.title[+lang]} ${company.comment[+lang]}`}
                                                value={comment.description}
                                                onChange={(ev) => {
                                                    setComment((prev) => ({ ...prev, description: ev.target.value }))
                                                }}/>
                                        </label>
                                        <div className="d-flex justify-content-center">
                                            <div onClick={handleCommentAdd}
                                                 className="m-0 createBtn">{global.add[+lang]} {company.comment[+lang]}
                                            </div>
                                        </div>

                                    </>
                                    : null}


                                {edit ?
                                    <span style={{fontSize: 18, marginTop: 25}}>{company.reminder[+lang]}</span> : null}


                                {allReminder.map(c => (
                                    <label key={c.id} className="col-md-12">
                                        <p className="d-flex align-items-center justify-content-between">
                                            <span/>

                                            {profileData.email === c.setter || profileData.is_admin ?
                                                <span style={{cursor: 'pointer'}}
                                                      onClick={() => handleReminderDelete(c.id)}>x</span> : null}
                                        </p>

                                        <textarea
                                            style={{marginBottom: 5}}
                                            placeholder={`${global.enter[+lang]} ${company.reminder[+lang]}`}
                                            value={c.description}
                                            onChange={(ev) => null}/>
                                        {c.createdAt ?
                                            <span style={{
                                                fontSize: 10,
                                                display: 'inline-block'
                                            }}>Created at: {new Date(c.createdAt).toLocaleString('en-US', {
                                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                                hour12: false,
                                            })}</span> : null}
                                        {c.setter ? <span style={{
                                            fontSize: 10,
                                            display: 'inline-block'
                                        }}>By: <span>{c.setter}</span></span> : null}
                                    </label>
                                ))}
                                {edit ?
                                    <>
                                        <label style={{marginTop: 25}} className="col-md-12">
                                            <textarea
                                                style={{marginBottom: 40}}
                                                placeholder={`${global.enter[+lang]} ${company.title[+lang]} ${company.reminder[+lang]}`}
                                                value={reminder.description}
                                                onChange={(ev) => handleReminder('description', ev.target.value)}/>
                                        </label>
                                        <div onClick={handleReminderAdd}
                                             style={{width: 'fit-content', margin: '0 auto'}}
                                             className="createBtn">{global.add[+lang]} {company.comment[+lang]}
                                        </div>

                                    </>
                                    : null}
                            </div>
                            {edit ? <div onClick={() => setOpen(true)} className="createBtn"
                                         style={{width: 'fit-content', margin: '20px auto'}}>
                                Company Card
                            </div> : null}
                            <div className="d-flex justify-content-center">
                                <button onClick={handleSubmit}
                                        className="createBtn m-0">{companyStatus === "request" ? (
                                    <div className="d-flex justify-content-center">
                                        <Loader/>
                                    </div>) : edit ? global.edit[+lang] : `${global.add[+lang]} ${company.title[+lang]}`}</button>
                            </div>
                        </form>
                        <CompanyHistory dataAll={dataAll} create={open} setCreate={setOpen} data={dataAll?.history}/>

                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default CompanyCreateAndEdit;
