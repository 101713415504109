import React, {useCallback, useEffect, useState} from 'react';
import AdminWrapper from "../components/AdminWrapper";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import qs from "query-string";
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsProgress, faBarsStaggered} from "@fortawesome/free-solid-svg-icons";
import Utils from "../helpers/Utils";
import {menu, processList} from "../menu";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskCreate from "../components/TaskCreate";
import ProcessCreate from "../components/ProcessCreate";
import {processDeleteRequest, processEditRequest, processRequest} from "../store/actions/process";
import ProcessDrop from "../components/ProcessDrop";
import ProcessDelete from "../components/ProcessDelete";

function ProcessLine() {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false);
    const [deleteItem, setDeleteItem] = useState(0);
    const [create, setCreate] = useState(false);
    const [isProcess, setIsProcess] = useState(0)
    const [boards, setBoards] = useState([]);
    const [currentBoard, setCurrentBoard] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)
    const [createTask, setCreateTask] = useState(false)
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const processData = useSelector(state => state.process.processData)
    const lang = useSelector(state => state.users.lang)
    const processStatus = useSelector(state => state.process.processStatus)

    useEffect(() => {
        (async () => {
            await dispatch(processRequest(1000, 1, query.filter || '', query.search || 'all'));
        })();
    }, []);

    useEffect(() => {
        setBoards(Utils.sortProcess(processData))
    }, [processData])


    function handleDragEnd(ev) {
        ev.target.style.boxShadow = 'none'
        setEdit(false)
    }

    function handleLeave(ev) {
        ev.target.style.boxShadow = 'none'

    }

    function handleDragStart(ev, board, item) {
        if(+ev.target.id === 0){
            ev.preventDefault()
        }else{
            setCurrentBoard(board)
            setCurrentItem(item)
            setEdit(true)
        }

    }

    function handleDragOver(ev) {
        ev.preventDefault()
        if (ev.target.className === 'item') {
            ev.target.style.boxShadow = '0 4px 3px #ccc'
        }
    }

    const handleDrop = useCallback(async (ev, board, item) => {
        ev.preventDefault()
        if (board.id === currentBoard.id) {
            return
        }
        const currentIndex = currentBoard.items.indexOf(currentItem)
        currentBoard.items.splice(currentIndex, 1)
        const dropIndex = board.items.indexOf(item)
        board.items.splice(dropIndex + 1, 0, currentItem)
        setBoards(boards.map(b => {
            if (b.id === board.id) {
                return board
            }
            if (b.id === currentBoard.id) {
                return currentBoard
            }
            return b
        }))

        ev.target.style.boxShadow = 'none'
        if (board.id === 1) {
            await dispatch(processEditRequest(currentItem.id, {
                ...currentItem,
                status:1
            }, 1, 1000, ''))
        } else if (board.id === 2) {
            await dispatch(processEditRequest(currentItem.id, {
                ...currentItem,
                status:2
            }, 1, 1000, ''))
        }else if(board.id === 3){
            await dispatch(processEditRequest(currentItem.id, {
                ...currentItem,
                status:3
            }, 1, 1000, ''))
        }else if(board.id === 4){
            await dispatch(processEditRequest(currentItem.id, {
                ...currentItem,
                status:4
            }, 1, 1000, ''))
        }else if(board.id === 5){
            await dispatch(processEditRequest(currentItem.id, {
                ...currentItem,
                status:5
            }, 1, 1000, ''))
        }
        setEdit(false)

    }, [boards, currentItem, currentBoard])

    const handleDragEndLi = useCallback((ev) => {
        ev.target.style.boxShadow = 'none'
    }, [])

    const handleLeaveLi = useCallback((ev) => {
        ev.target.style.boxShadow = 'none'

    }, [])

    const handleDragStartLi = useCallback((ev, board, item) => {
        ev.preventDefault()
        setCurrentBoard(board)
        setCurrentItem(item)
        setEdit(true)
    }, [])

    const handleDragOverLi = useCallback((ev) => {
        ev.preventDefault()
        if (ev.target.className === 'item') {
            ev.target.style.boxShadow = '0 4px 3px #ccc'
        }
        if (ev.target.className === 'itemTrans') {
            ev.target.style.backgroundColor = '#ccc'
        }
    }, [])

    const handleDropLi = useCallback(async (ev) => {
        ev.preventDefault()

        if (+ev.target.id === 1) {
            setDeleteItem(currentItem.id)
        } else if (+ev.target.id === 4) {
            await dispatch(processEditRequest(currentItem.id, {...currentItem, status: 4}, 1, 1000, ''))
        } else if (+ev.target.id === 3) {
            await dispatch(processEditRequest(currentItem.id, {...currentItem, status: 5}, 1, 1000, ''))
        }

    }, [currentItem])


    return (
        <>
            <Helmet>
                <title>{menu[7].title[+lang]}</title>
            </Helmet>
                <header className="taskFilter">
                    <div
                        style={{margin:window.innerWidth < 450? '0 0px 0px 90px': 0,padding:window.innerWidth < 450? ' 0 10px 0 0':0}}
                        className="menuLink">
                        <NavLink to={`/process/line`}>
                            <FontAwesomeIcon icon={faBarsProgress}/>
                        </NavLink>
                        <NavLink to='/process/list'>
                            <FontAwesomeIcon icon={faBarsStaggered}/>
                        </NavLink>
                    </div>
                    <label className="d-flex align-items-center" style={{width: '60%'}}>
                        Filters
                        <ProcessDrop line={true}/>
                    </label>
                    <button className="taskBtn" onClick={() => setCreate(true)}>
                        Add process
                    </button>
                </header>


                <div className="mainTask justify-content-center" style={{paddingBottom: 150,width:'fit-content',flexFlow:'wrap'}}>
                    {boards.map((board) => {
                        return (
                            <div
                                id={board.id}
                                key={board.id}>

                                <div className={board.class}>
                                    <span>{board.content}</span>
                                    <span className="count">{board.items.length} task</span>
                                </div>
                                {board.items.map((item) => (
                                    <div
                                        id={item.id}
                                        onDragStart={(ev) => handleDragStart(ev, board, item)}
                                        onDragEnd={(ev) => handleDragEnd(ev, item)}
                                        onDragOver={(ev) => handleDragOver(ev)}
                                        onDragLeave={(ev) => handleLeave(ev, item)}
                                        onDrop={(ev) => handleDrop(ev, board, item)}
                                        onTouchStart={(ev) => handleDragStart(ev, board, item)}
                                        onTouchEnd={(ev) => handleDragEnd(ev, item)}
                                        onTouchMove={(ev) => handleDragOver(ev)}
                                        onTouchCancel={(ev) => handleLeave(ev, item)}
                                        onTouchDrop={(ev) => handleDrop(ev, board, item)}
                                        draggable={true}
                                        key={item.id}
                                        className="item"
                                        onClick={() => {
                                            navigate(`/process/${item.id}`)
                                            // setCreateTask(true)
                                            // setIsProcess(item.id)
                                        }}
                                    >
                                        <span className="d-flex flex-column align-items-center w-100 justify-content-center">
                                                <b style={{fontSize: 12,padding:'10px 0',width:'100%',borderBottom:'1px solid #ccc'}}>{item.company.title}</b>
                                                <b style={{fontSize: 12,padding:'10px 0',width:'100%',borderBottom:'1px solid #ccc'}}>{new Date(item.createdAt).toLocaleString('en-US', {
                                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                                    hour12: false,
                                                })}</b>
                                                <b style={{fontSize: 12,padding:'10px 0',width:'100%',borderBottom:'1px solid #ccc'}}>{item.description}</b>
                                                <b style={{fontSize: 12,padding:'10px 0',width:'100%',borderBottom:'1px solid #ccc'}}>{item.company.phone}</b>
                                                <b style={{fontSize: 12,padding:'10px 0',width:'100%',borderBottom:'1px solid #ccc'}}>{item.process_employee.user.email}</b>
                                                <span style={item.task_process.length ? {
                                                    color: 'blue',
                                                    marginLeft: 3,
                                                    fontSize: 10,
                                                    padding:'10px 0',
                                                } : {
                                                    color: 'orange',
                                                    padding:'10px 0',
                                                    marginLeft: 3,
                                                    fontSize: 10
                                                }}>{item.task_process.length ? 'View tasks' : 'no task'}</span>
                                            </span>
                                    </div>
                                ))}
                                <div
                                    id={0}
                                    style={{border:'none'}}
                                    onDragStart={(ev) => handleDragStart(ev, board, {})}
                                    onDragEnd={(ev) => handleDragEnd(ev, {})}
                                    onDragOver={(ev) => handleDragOver(ev)}
                                    onDragLeave={(ev) => handleLeave(ev, {})}
                                    onDrop={(ev) => handleDrop(ev, board, {})}
                                    onTouchStart={(ev) => handleDragStart(ev, board, {})}
                                    onTouchEnd={(ev) => handleDragEnd(ev, {})}
                                    onTouchMove={(ev) => handleDragOver(ev)}
                                    onTouchCancel={(ev) => handleLeave(ev, {})}
                                    onTouchDrop={(ev) => handleDrop(ev, board, {})}
                                    draggable={true}
                                    className="itemTrans"
                                />
                            </div>
                        )


                    })}
                </div>
                {edit ? <div className="taskLineEdit">
                    <ul>
                        {processList.map((l) => (<li
                            key={l.id}
                            id={l.id}
                            onDragStart={(ev) => handleDragStartLi(ev)}
                            onDragEnd={(ev) => handleDragEndLi(ev)}
                            onDragOver={(ev) => handleDragOverLi(ev)}
                            onDragLeave={(ev) => handleLeaveLi(ev)}
                            onDrop={(ev) => handleDropLi(ev)}
                            onTouchStart={(ev) => handleDragStartLi(ev)}
                            onTouchEnd={(ev) => handleDragEndLi(ev)}
                            onTouchMove={(ev) => handleDragOverLi(ev)}
                            onTouchCancel={(ev) => handleLeaveLi(ev)}
                            onTouchDrop={(ev) => handleDropLi(ev)}
                            draggable={true}
                            className={l.icon ? "delete" : ''}>{l.icon ? <DeleteIcon/> : l.text}</li>)
                        )}
                    </ul>
                </div> : null}
                {create?<ProcessCreate create={create} line={true} setCreate={setCreate}/>:null}
                <TaskCreate setIsProcess={setIsProcess} isProcess={isProcess} create={createTask} line={true}
                            setCreate={setCreateTask}/>
                <ProcessDelete deleteItem={deleteItem} setDelete={setDeleteItem} />
        </>
    );
}

export default ProcessLine;
