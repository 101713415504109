import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import AsideMenu from "./AsideMenu";
import img from "../assets/img/post/images.jpg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {REACT_APP_API_URL} from '../config';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutIcon from '@mui/icons-material/Logout';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';


import Account from "../helpers/Account";
import Api from "../Api";
import {profileUserRequest} from "../store/actions/users";
import LanguageDrop from "./LanguageDrop";
import {top} from "../translate";

function AdminHeader({children, main}) {
    const [show, setShow] = useState(false)
    const userData = useSelector((state) => state.users.userData)
    const lang = useSelector((state) => state.users.lang)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initialize with initial window width

    useEffect(() => {
        // Add event listener for window resize
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <section className="adminHeader">
            {windowWidth < 450 ? <>
                    <figure
                        onClick={() => setShow(!show)}
                        style={{position: 'absolute', width: 80, height: 65, cursor: 'pointer', zIndex: 700}}>
                        <img className="headerProfileImg" src={REACT_APP_API_URL + userData?.image?.path || ''} alt=""/>
                    </figure>
                    <div style={{
                        zIndex: 100,
                        position: 'absolute',
                        left: show ? 0 : '-100%',
                        transition: 'all 0.4s'
                    }}>
                        <aside
                            className={classNames('adminSideBar')}>

                            <nav className="d-flex align-items-center position-relative">

                                <div style={{
                                    height: 65,
                                }} className={classNames("navProfile flex-column justify-content-center", {show: !show})}>
                                    <div className="d-flex flex-column justify-content-center"
                                         style={{padding: '8px 0px 0 15px'}}>
                                        <span>{userData?.first_name}</span>
                                        <span style={{fontSize: 13}}>Your sip number: {userData?.sip_operator}</span>
                                    </div>
                                    <div className="d-flex flex-row" style={{paddingRight: 10}}>
                                        <Link style={{marginRight: 10, textDecoration: 'none', cursor: 'pointer'}}
                                              to='/profile'
                                              className="d-flex flex-column align-items-center justify-content-center">
                                            <span><AccountCircleIcon/></span>
                                            <span>Profile</span>
                                        </Link>
                                        <LanguageDrop/>
                                        <span style={{width: 75, cursor: 'pointer'}}
                                              className="d-flex flex-column align-items-center justify-content-center"
                                              onClick={() => {
                                                  Account.logoutAdmin()
                                                  window.location.reload()
                                              }}>
                                <span><PowerSettingsNewIcon/></span>
                                <span>Logout</span>
                            </span>
                                        {Cookies.get('original') ? <span style={{width: 75, cursor: 'pointer'}}
                                                                         className="d-flex flex-column align-items-center justify-content-center"
                                                                         onClick={async () => {
                                                                             const {data} = await Api.loginAsUser(Cookies.get('original'), Cookies.get('original'), Cookies.get('key'))
                                                                             localStorage.setItem('access_token', data.access_token)
                                                                             localStorage.setItem('refresh_token', data.refresh_token)
                                                                             Cookies.remove('original');
                                                                             Cookies.remove('viewer');
                                                                             Cookies.remove('key');
                                                                             await dispatch(profileUserRequest())
                                                                         }}>
                                <span><LogoutIcon/></span>
                                <span>Return</span>
                            </span> : null}
                                    </div>
                                </div>
                            </nav>
                            <AsideMenu windowWidth={windowWidth}/>
                        </aside>
                    </div>
                </>
                : <aside className={classNames('adminSideBar')}>
                    <nav className="d-flex align-items-center position-relative">
                        <figure
                            onClick={() => setShow(!show)}
                            style={{width: 80, height: 60, cursor: 'pointer', zIndex: 700}}>
                            <img className="headerProfileImg" src={REACT_APP_API_URL + userData?.image?.path || ''}
                                 alt=""/>
                        </figure>
                        <div className={classNames("navProfile justify-content-between", {show: !show})}>
                            <div className="d-flex flex-column" style={{padding: '5px 20px'}}>
                                <span>{userData?.first_name}</span>
                                <span style={{fontSize: 13}}>{top.sip[+lang]}: {userData?.sip_operator}</span>
                            </div>
                            <div className="d-flex flex-row" style={{paddingRight: 10}}>
                                <Link style={{marginRight: 10, textDecoration: 'none', cursor: 'pointer'}} to='/profile'
                                      className="d-flex flex-column align-items-center justify-content-center">
                                    <span><AccountCircleIcon/></span>
                                    <span style={{fontSize: 12, cursor: 'pointer'}}>{top.profile[+lang]}</span>
                                </Link>
                                <span style={{width: 75, cursor: 'pointer'}}
                                      className="d-flex flex-column align-items-center justify-content-center"
                                      onClick={() => {
                                          Account.logoutAdmin()
                                          window.location.reload()
                                      }}>
                                <span><PowerSettingsNewIcon/></span>
                                <span style={{fontSize: 12, cursor: 'pointer'}}>{top.logout[+lang]}</span>
                            </span>
                                <LanguageDrop/>

                                {Cookies.get('original') ? <span style={{width: 75, cursor: 'pointer'}}
                                                                 className="d-flex flex-column align-items-center justify-content-center"
                                                                 onClick={async () => {
                                                                     const {data} = await Api.loginAsUser(Cookies.get('original'), Cookies.get('original'), Cookies.get('key'))
                                                                     localStorage.setItem('access_token', data.access_token)
                                                                     localStorage.setItem('refresh_token', data.refresh_token)
                                                                     Cookies.remove('original');
                                                                     Cookies.remove('viewer');
                                                                     Cookies.remove('key');
                                                                     await dispatch(profileUserRequest())
                                                                 }}>
                                <span><LogoutIcon/></span>
                                <span>Return</span>
                            </span> : null}
                            </div>
                        </div>
                    </nav>
                    <AsideMenu/>
                </aside>
            }
            <div className="adminRow">
                {/*<header className="d-flex align-items-center justify-content-between"*/}
                {/*     style={{width: '100%',height:60, padding: '10px 35px',boxShadow: '0 12px 30px rgba(80,143,244,.1)'}}>*/}
                {/*    <div className="d-flex align-items-center">*/}
                {/*        <div className="search-box">*/}
                {/*            <button className="btn-search"><SearchIcon style={{fontSize: 20}} /></button>*/}
                {/*            <input type="text" className="input-search" placeholder="Type to Search..."/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <div className="d-flex align-items-center">*/}
                {/*            /!*<LiveChat/>*!/*/}
                {/*            /!*<span className="d-flex justify-content-center align-items-center position-relative" style={{height:30, width:30, borderRadius:'100%', background:'#afe1ff82'}}>*!/*/}
                {/*            /!*    <ChatIcon style={{fontSize: 20, color: '#9cdaff'}}/>*!/*/}
                {/*            /!*    <span className="icon__count position-absolute">2</span>*!/*/}
                {/*            /!*</span>*!/*/}

                {/*            <CallWidget />*/}

                {/*            <LanguageDrop/>*/}
                {/*            <div style={{*/}
                {/*                margin: ' 0 20px',*/}
                {/*                height: 51,*/}
                {/*                width: 2,*/}
                {/*                background: '#9c9c9c'*/}
                {/*            }}/>*/}
                {/*            <ProfileDrop/>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</header>*/}
                <div className={classNames('adminMain', {'p-0': !main})}>
                    <div className={classNames("position-absolute", {back: show})}/>
                    {children}
                </div>
            </div>
            {/*<Profile show={showProfileModal} handleShow={setShowProfileModal}/>*/}
        </section>
    );
}

export default AdminHeader;
